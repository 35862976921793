"use client";

import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { cn, getBgColor, getTextColorByBgColor, strapiMediaAlt, strapiMediaUrl } from "@/lib/utils";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/components/ui/carousel";
import { cva } from "class-variance-authority";
import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import Tag from "@/components/ui/tag";
import { ButtonLink } from "@/components/ui/button";

const sliderVariants = cva("pl-5 grow-0 shrink-0 min-w-0 ", {
  variants: {
    sizes: {
      two_cards_grid: "basis-[80%] md:basis-[50%]",
      two_cards_slider: "basis-[80%] md:basis-[50%]",
      three_cards_slider: "basis-[80%] md:basis-[50%] lg:basis-[33.333%]",
      four_cards_slider: "basis-[80%] md:basis-[33.333%] lg:basis-[25%]",
      three_cards_grid: "basis-[80%] md:basis-[50%] lg:basis-[33.333%]",
      four_cards_grid: "basis-[80%] md:basis-[33.333%] lg:basis-[25%]",
    },
  },
  defaultVariants: {
    sizes: "three_cards_slider",
  },
});

const PostsArchiveSection = ({ data }: any) => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <section
      style={{
        backgroundColor: getBgColor(data.backgroundColor),
      }}
      className={`relative h-full py-16 md:py-24 ${data.backgroundColor === "jsc-sky-blue" ? "jsc-sky-blue-bg-section" : ""} small-ellipse-section`}
    >
      <div className="container">
        <div className="mb-6 flex flex-wrap items-end justify-between gap-4 md:mb-12 md:gap-6">

          <h2
            className="header-2 !text-jsc-sky-blue md:!text-[81px]"
            style={{
              color: getTextColorByBgColor(data.backgroundColor),
            }}
          >{data.title}</h2>

          <ButtonLink
            style={{
              color: getTextColorByBgColor(data.backgroundColor),
            }}
            arrowColor={getTextColorByBgColor(data.backgroundColor)}
            className="mt-12 !font-oswald !uppercase"
            href="/nieuws"
          >
            Ga naar het nieuwsoverzicht
          </ButtonLink>
        </div>

        {data.type?.includes("grid") && (
          <div className={cn("-ml-5 hidden w-auto", "flex-wrap gap-y-16 lg:flex")}>
            {data.items.map((item: any) => {
              return (
                <div className={sliderVariants({ sizes: data.type })} key={item.id}>
                  <Card data={data} item={item} />
                </div>
              );
            })}
          </div>
        )}

        <Carousel
          opts={{
            align: "start",
          }}
          className={cn("w-screen md:w-auto", data.type?.includes("grid") && "lg:hidden")}
          setApi={setApi}
        >
          <CarouselContent className="mb-8 md:mb-12">
            {data.items.map((item: any) => (
              <CarouselItem key={item.id} className={sliderVariants({ sizes: data.type })}>
                <Card data={data} item={item} />
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="flex w-[calc(100vw-20px)] items-center justify-between md:w-full">
            <CarouselPrevious arrowColor="white" className="bg-jsc-sky-blue" />
            <div className="flex justify-center gap-2">
              {count > 1 &&
                count < 6 &&
                Array.from({ length: count }).map((_, index) => (
                  <motion.button
                    aria-label={`Scroll naar slide ${index + 1}`}
                    onClick={() => api?.scrollTo(index)}
                    animate={{ opacity: current === index + 1 ? 1 : 0.5 }}
                    key={index}
                    className="h-4 w-4 rounded-full bg-jsc-sky-blue p-0"
                  />
                ))}
            </div>
            <CarouselNext arrowColor="white" className="bg-jsc-sky-blue [&>svg]:!fill-white [&>svg]:stroke-white" />
          </div>
        </Carousel>
      </div>
    </section>
  );
};

export default PostsArchiveSection;

const Card = ({
  data, item
}: any) => {
  return (
    <Link className="group h-full" href={`/nieuws/${item.attributes.slug}`}>
      <article className="flex h-full flex-col gap-5 ">
        <figure
          className={`relative w-full overflow-hidden aspect-[0.9] rounded-3xl `}
        >
          {item.attributes.thumbnail.data ? (
            <Image
              src={strapiMediaUrl(item.attributes.thumbnail)}
              alt={strapiMediaAlt(item.attributes.thumbnail)}
              className="rounded object-cover transition-transform duration-500 group-hover:scale-105"
              sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw"
              fill
            />
          ) : (
            <div className="h-full w-full bg-gray-300" />
          )}
        </figure>

        {item.attributes.tags && item.attributes.tags.length > 0 && (
          <div className="flex flex-wrap items-center gap-2">
            {item.attributes.tags.map((tag: any) => <Tag variant={tag.name} key={tag.name}
                                                         color={getTextColorByBgColor(data.backgroundColor)} />)}
          </div>
        )}

        <h3
          className={cn(
            "max-w-[300px] text-balance font-oswald text-xl font-bold uppercase !leading-normal",
          )}
          style={{
            color: getTextColorByBgColor(data.backgroundColor),
          }}
        >
          {item.attributes.title}
        </h3>
      </article>
    </Link>
  );
}
