"use client";

import { getBgColor, getTextColorByBgColor, strapiMediaAlt, strapiMediaUrl } from "@/lib/utils";
import RichText from "@/components/general/jsc/RichText";
import Image from "next/image";
import { StrapiMedia } from "@/lib/types";

interface BoardMember {
  id: number;
  name: string;
  role: string;
  content: any;
  image: StrapiMedia;
}

const BoardMembersSection = ({ data }: any) => {
  return (
    <section
      style={{
        backgroundColor: getBgColor(data.backgroundColor),
        overflow: "hidden",
      }}
      className={`relative h-full py-16 md:py-24 ${data.backgroundColor === "jsc-sky-blue" ? "jsc-sky-blue-bg-section" : ""} large-ellipse-section`}
    >
      <div className="grid-container container col-span-12 gap-14 md:gap-16 mb-12 md:mb-24">
        <div className="col-span-12 flex flex-col gap-5 md:col-span-12">
          <h2
            style={{
              color: getTextColorByBgColor(data.backgroundColor),
            }}
            className="header-2 !leading-[41px] md:!leading-[75px]"
          >
            {data.title}
          </h2>
          <RichText bgColor={data.backgroundColor} data={data.content} className="rich-text" />
        </div>
      </div>

      <div className="grid-container container col-span-12 gap-14 md:gap-16">
        <div className="col-span-12 flex flex-col gap-5 md:col-span-12">
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3 md:gap-17 lg:gap-24">
            {data.members.map((member: BoardMember) => (
              <article key={member.id} className="flex flex-col gap-5" aria-labelledby={`${member.name}`}>
                <div className="relative w-full h-[350px] rounded-3xl overflow-hidden mb-5">
                  <Image
                    src={strapiMediaUrl(member.image)}
                    alt={strapiMediaAlt(member.image)}
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
                <h3
                  className="header-3 mb-5"
                  style={{
                    color: getTextColorByBgColor(data.backgroundColor),
                  }}
                >{member.role}</h3>
                <h4
                  className="header-4"
                  style={{
                    color: getTextColorByBgColor(data.backgroundColor),
                  }}
                  id={`${member.name}`}
                >{member.name}</h4>
                <RichText bgColor={data.backgroundColor} data={member.content} className="rich-text" />
              </article>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BoardMembersSection;
