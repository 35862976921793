"use client";

import { cn, getBgColor, getTextColorByBgColor } from "@/lib/utils";
import DynamicForm from "@/components/general/DynamicForm";
import { createSubmission } from "@/lib/api/submission";

const QuestionForm = ({ data, noContainer }: any) => {
  const handleSubmit = async (formData: FormData) => {
    const rawFormData = {
      name: formData.get("name"),
      subject: `Vraag van ${window.location.href}`,
      email: formData.get("email"),
      message: formData.get("message"),
      type: "question",
      metadata: {
        pageUrl: window.location.href,
        referrer: document.referrer,
        userAgent: navigator.userAgent,
        language: navigator.language,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cookiesEnabled: navigator.cookieEnabled,
      }
    };

    return createSubmission(rawFormData);
  };

  const formFields = [
    {
      id: "name",
      label: "Voornaam*",
      type: "text",
      required: true,
      errorMessage: "Voornaam is verplicht in te vullen",
      colSpan: "md:col-span-6",
    },
    {
      id: "email",
      label: "E-mailadres*",
      type: "email",
      required: true,
      errorMessage: "E-mail is verplicht in te vullen",
      autoComplete: "email",
      colSpan: "md:col-span-6",
    },
    { id: "message", label: "Vraag*", type: "textarea" },
  ];

  return (
    <section
      style={{
        backgroundColor: getBgColor(data.backgroundColor),
      }}
    >
      <div className={cn("mx-auto py-20 md:py-24", !noContainer && "grid-container container")}>
        <div className="col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3">
          <h2
            style={{
              color: data.backgroundColor === "white" ? "var(--color-jsc-sky-blue)" : getTextColorByBgColor(data.backgroundColor),
            }}
            className={cn("header-2 mb-8 !text-balance text-center")}
          >
            {data?.title}
          </h2>
          <DynamicForm
            textNotWhite
            fields={formFields}
            onSubmit={handleSubmit}
            buttonLabel="Vraag versturen"
            buttonBackgroundColor={data.buttonBackgroundColor}
            buttonFit
            sendMessage="Bedankt voor uw vraag. We nemen zo snel mogelijk contact met u op."
          />
        </div>
      </div>
    </section>
  );
};

export default QuestionForm;
