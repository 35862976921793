"use client";

import { TagVariant } from "@/lib/types";
import { cn, getBgColor, getTextColorByBgColor, getUniqueTags } from "@/lib/utils";
import { useEffect, useState } from "react";
import Tag from "@/components/ui/tag";
import Arrow from "@/components/icons/Arrow";
import RichText from "@/components/general/jsc/RichText";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { useSearchParams } from "next/navigation";

const AccordionSpeerpuntenArchiveSection = ({ data }: any) => {
  const searchParams = useSearchParams();
  let uniqueTags: TagVariant[] = [];

  if (data.showTags) {
    uniqueTags = getUniqueTags(
      data.items.map((item: any) => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            tags: item.attributes.tags.map((tag: any) => {
              return tag.name;
            }),
          },
        };
      })
    );
  }

  const [openItem, setOpenItem] = useState(data.items[0]);

  useEffect(() => {
    const slug = searchParams.get("standpunt");

    if (slug && data?.items) {
      // Ensure slug and items exist
      let element;
      if (window.innerWidth > 768) {
        element = document.getElementById("accordion-content");
      } else {
        element = document.getElementById("accordion-content-mobile");
      }

      if (element) {
        // Calculate position and scroll smoothly
        const y = element.getBoundingClientRect().top + window.scrollY - 200;

        // Use a small timeout to ensure layout stability before scrolling
        setTimeout(() => {
          window.scrollTo({ top: y, behavior: "smooth" });
        }, 0);
      }

      // Find item by slug and set open item
      const item = data.items.find((item: any) => item?.attributes?.slug === slug);
      if (item) {
        setOpenItem(item);
      }
    }
  }, [searchParams, data.items]);

  return (
    <section
      style={{
        backgroundColor: getBgColor(data.backgroundColor),
      }}
      className={`relative h-full py-16 md:py-24 ${data.backgroundColor === "jsc-sky-blue" ? "jsc-sky-blue-bg-section" : ""} small-ellipse-section`}
    >
      <div className="container">
        {/* Desktop */}
        <div className="hidden grid-cols-12 gap-x-5 md:grid">
          <div className="col-span-12 mb-6">
            <h2
              className="header-2 mb-12 md:!text-[81px]"
              style={{
                color: getTextColorByBgColor(data.backgroundColor),
              }}
            >
              {data.title}
            </h2>

            {uniqueTags.length > 0 && (
              <div className="flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
                <div
                  className="h-px w-full flex-1 opacity-40"
                  style={{
                    backgroundColor: getTextColorByBgColor(data.backgroundColor),
                  }}
                />
                <div className="flex gap-2">
                  {uniqueTags.map((tag) => (
                    <Tag key={tag} variant={tag} rounded />
                  ))}
                </div>
              </div>
            )}
          </div>

          <div id="accordion-content" className="col-span-4">
            {data.items.map((item: any) => (
              <div
                key={item.id}
                className="group flex flex-col gap-5 border-b py-6 md:col-span-6 md:flex-row md:items-center md:gap-5"
                style={{
                  borderColor: getTextColorByBgColor(data.backgroundColor),
                }}
              >
                <button
                  className="max-w-[400px]"
                  onClick={() => setOpenItem(item)}
                  style={{
                    color: getTextColorByBgColor(data.backgroundColor),
                  }}
                >
                  <span>{item.attributes.title}</span>
                </button>
                <Arrow
                  color={getTextColorByBgColor(data.backgroundColor)}
                  direction={openItem.id === item.id ? "down" : "right"}
                  className={cn(
                    "ml-auto transition-transform group-hover:translate-x-1",
                    openItem.id === item.id ? "!translate-x-2 rotate-90" : ""
                  )}
                />
              </div>
            ))}
          </div>

          <div className="col-span-8 col-start-6">
            {openItem.attributes.shortDescription && (
              <>
                <h3
                  className="header-3 mb-8"
                  style={{
                    color: getTextColorByBgColor(data.backgroundColor),
                  }}
                >
                  {openItem.attributes.title}
                </h3>
                <RichText
                  bgColor={data.backgroundColor}
                  data={openItem.attributes.shortDescription}
                  className="rich-text"
                />
              </>
            )}
          </div>
        </div>

        {/* Mobile */}
        <div className="md:hidden">
          <h2
            id="accordion-content-mobile"
            className="header-2 mb-12"
            style={{
              color: getTextColorByBgColor(data.backgroundColor),
            }}
          >
            {data.title}
          </h2>

          {uniqueTags.length > 0 && (
            <div className="flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
              <div className="h-px w-full flex-1 bg-jsc-dark-gray opacity-40" />
              <div className="flex gap-2">
                {uniqueTags.map((tag) => (
                  <Tag key={tag} variant={tag} rounded />
                ))}
              </div>
            </div>
          )}

          <Accordion type="single" collapsible className="w-full" value={openItem.id}>
            {data.items.map((item: any) => (
              <AccordionItem
                key={item.id}
                value={item.id}
                style={{
                  borderColor: getTextColorByBgColor(data.backgroundColor),
                }}
              >
                <AccordionTrigger
                  id={item.attributes.slug}
                  onClick={() => setOpenItem(item)}
                  className="font-normal"
                  style={{
                    color: getTextColorByBgColor(data.backgroundColor),
                    borderColor: getTextColorByBgColor(data.backgroundColor),
                  }}
                >
                  {item.attributes.title}
                </AccordionTrigger>
                <AccordionContent>
                  {item.attributes.shortDescription && (
                    <RichText
                      bgColor={data.backgroundColor}
                      data={openItem.attributes.shortDescription}
                      className="rich-text"
                    />
                  )}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default AccordionSpeerpuntenArchiveSection;
