"use client";

import { getBgColor, getTextColorByBgColor, strapiMediaAlt, strapiMediaUrl } from "@/lib/utils";
import RichText from "@/components/general/jsc/RichText";
import Image from "next/image";
import { StrapiMedia } from "@/lib/types";
import { useEffect, useRef, useState } from "react";

interface BoardMember {
  id: number;
  name: string;
  role: string;
  content: any;
  image: StrapiMedia;
}

const InternationalSecretariatSection = ({ data }: any) => {
  return (
    <section
      style={{
        backgroundColor: getBgColor(data.backgroundColor),
      }}
      className={`relative h-full py-16 md:py-24 ${data.backgroundColor === "jsc-sky-blue" ? "jsc-sky-blue-bg-section" : ""}`}
    >
      <div className="grid-container container col-span-12 gap-14 md:gap-16 mb-12 md:mb-24">
        <div className="col-span-12 flex flex-col gap-5 md:col-span-12">
          <h2
            style={{
              color: getTextColorByBgColor(data.backgroundColor),
            }}
            className="header-2 !leading-[41px] md:!leading-[75px]"
          >
            {data.title}
          </h2>
          <RichText bgColor={data.backgroundColor} data={data.content} className="rich-text" />
        </div>
      </div>

      <div className="grid-container container col-span-12 gap-14 md:gap-16">
        <div className="col-span-12 md:col-span-4">
          <article key={data.member.id} className="flex flex-col gap-5" aria-labelledby={`${data.member.name}`}>
            <div className="relative w-full h-[350px] rounded-3xl overflow-hidden mb-5">
              <Image
                src={strapiMediaUrl(data.member.image)}
                alt={strapiMediaAlt(data.member.image)}
                layout="fill"
                objectFit="cover"
              />
            </div>
            <h3
              className="header-3 mb-5"
              style={{
                color: getTextColorByBgColor(data.backgroundColor),
              }}
            >{data.member.role}</h3>
            <h4
              className="header-4"
              style={{
                color: getTextColorByBgColor(data.backgroundColor),
              }}
              id={`${data.member.name}`}
            >{data.member.name}</h4>
            <RichText bgColor={data.backgroundColor} data={data.member.content} className="rich-text" />
          </article>
        </div>
        <div className="col-span-12 md:col-span-8 relative min-h-[350px]">
          <Image
            src={strapiMediaUrl(data.image)}
            alt={strapiMediaAlt(data.image)}
            layout="fill"
            objectFit="cover"
            className="rounded-3xl"
          />
        </div>
      </div>
    </section>
  )
}

export default InternationalSecretariatSection;
