"use client";

import { useCallback, useState } from "react";
import { useRouter } from "next/navigation";
import { usePathname, useSearchParams } from "next/navigation";
import { TagVariant } from "@/lib/types";
import { getUniqueTags } from "@/lib/utils";
import Tag from "@/components/ui/tag";
import Calendar from "./Calendar";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import { getVariantByCollection } from "@/components/strapi/jsc/PaginatedArchiveSection";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { PROVINCES } from "@/lib/constants";

const AgendaFull = ({ data }: any) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [selectedProvince, setSelectedProvince] = useState(data.searchParams.province ?? "");

  const pagination = data.agendaItems.meta.pagination;
  const items = data.agendaItems.data.map((item: any) => {
    return {
      ...item,
      attributes: {
        ...item.attributes,
        tags: item.attributes.tags.map((tag: any) => {
          return tag.name;
        }),
      },
    };
  });

  let uniqueTags: TagVariant[] = [];
  uniqueTags = getUniqueTags(items);

  //group items per month
  const groupedItems = items.reduce((acc: any, item: any) => {
    const date = new Date(item.attributes.date);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    if (!acc[month]) {
      acc[month] = [];
    }

    acc[month].push(item);

    return acc;
  }, {});

  const renderPaginationItems = () => {
    let items = [];
    let startPage = Math.max(1, pagination.page - 2);
    let endPage = Math.min(pagination.pageCount, pagination.page + 2);

    if (endPage - startPage < 4) {
      endPage = Math.min(pagination.pageCount, startPage + 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i}>
          <PaginationLink
            style={{ opacity: i === pagination.page ? 1 : 0.2 }}
            variant={getVariantByCollection("agenda")}
            className="h-4 w-4 rounded-full p-0"
            href={`/agenda?page=${i}`}
          />
        </PaginationItem>
      );
    }

    return items;
  };

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  return (
    <section className="container w-full">
      {/* {data?.title && (
        <div className="mb-12 flex items-center gap-6 md:mb-24">
          <h2 className="header-3">{data.title}</h2>
          <div className="h-px w-full flex-1 bg-nsc-dark-blue opacity-40" />
          <div className="flex gap-2">
            {uniqueTags.map((tag) => (
              <Tag key={tag} variant={tag} rounded />
            ))}
          </div>
        </div>
      )} */}

      <div className="mb-12 flex items-center justify-end gap-4 md:mb-0">
        <Label className="text-base font-medium">Filter op provincie:</Label>
        <Select
          value={selectedProvince}
          onValueChange={(value: string) => {
            setSelectedProvince(value);
            router.push(pathname + "?" + createQueryString("province", value), { scroll: false });
          }}
        >
          <SelectTrigger className="w-[180px] text-base font-medium" aria-label="Kies provincie">
            <SelectValue placeholder="Heel Nederland" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={"all"}>Heel Nederland</SelectItem>
            {PROVINCES.map((province: any) => (
              <SelectItem value={province} key={province}>
                {province}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {items.length === 0 && (
        <div className="col-span-12 mt-16">
          <p className="header-5 text-center">Er zijn geen resultaten gevonden voor de provincie {selectedProvince}.</p>
        </div>
      )}

      <div className="flex flex-col gap-24">
        {Object.keys(groupedItems).map((key: any, index: number) => {
          const month = new Date(groupedItems[key][0].attributes.date).toLocaleString("nl-NL", { month: "long" });
          const year = new Date(groupedItems[key][0].attributes.date).getFullYear();
          return (
            <div className="flex flex-col gap-16" key={key}>
              <h3 className="header-3">
                {month} {year}
              </h3>
              <Calendar
                data={{
                  variant: "bg-jsc-sky-blue",
                  noLink: true,
                  ShowSearch: index === 0,
                  noHeader: true,
                  nscAgenda: {
                    data: groupedItems[key],
                  },
                }}
              />
            </div>
          );
        })}
      </div>

      {pagination && items.length > 0 && pagination.pageCount > 1 && (
        <Pagination className="mt-16 w-full">
          <PaginationContent className="w-full justify-between">
            <PaginationItem>
              <PaginationPrevious
                disabled={pagination.page === 1}
                variant={getVariantByCollection("agenda")}
                href={`/agenda?page=${pagination.page - 1}`}
              />
            </PaginationItem>
            <div className="flex items-center gap-2">{renderPaginationItems()}</div>
            <PaginationItem>
              <PaginationNext
                disabled={pagination.page === pagination.pageCount}
                variant={getVariantByCollection("agenda")}
                href={`/agenda?page=${pagination.page + 1}`}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      )}
    </section>
  );
};

export default AgendaFull;
