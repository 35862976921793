"use client";

import { TagVariant } from "@/lib/types";
import { cn, getTextColorByBgColor, getUniqueTags } from "@/lib/utils";
import { useState } from "react";
import Tag from "@/components/ui/tag";
import Arrow from "@/components/icons/Arrow";
import RichText from "@/components/general/jsc/RichText";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

const SimpleAccordionSection = ({ data }: any) => {
  let uniqueTagsSet: Set<TagVariant> = new Set();
  let uniqueTags: TagVariant[] = [];

  if (data.tags) {
    data.tags.forEach((tag: any) => uniqueTagsSet.add(tag.name));
  }

  uniqueTags = Array.from(uniqueTags);

  const [openItem, setOpenItem] = useState(data.items[0]);

  return (
    <section
      className={`relative h-full py-16 md:py-24`}
    >
      <div className="container">
        {/* Desktop */}
        <div className="hidden grid-cols-12 gap-x-5 md:grid">
          <div className="col-span-12 mb-6">
            <h2
              className="header-2 md:!text-[81px] mb-12 text-jsc-sky-blue"
            >{data.title}</h2>

            {uniqueTags.length > 0 && (
              <div className="flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
                <div className="h-px w-full flex-1 opacity-40" style={{
                  backgroundColor: getTextColorByBgColor(data.backgroundColor),
                }} />
                <div className="flex gap-2">
                  {uniqueTags.map((tag) => (
                    <Tag key={tag} variant={tag} rounded />
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="col-span-4">
            {data.items.map((item: any) => (
              <div
                key={item.id}
                className="group flex flex-col gap-5 border-b py-6 md:col-span-6 md:flex-row md:items-center md:gap-5"
                style={{
                  borderColor: getTextColorByBgColor(data.backgroundColor),
                }}
              >
                <button
                  className="max-w-[400px]"
                  onClick={() => setOpenItem(item)}
                  style={{
                    color: getTextColorByBgColor(data.backgroundColor),
                  }}
                >
                  <span>{item.title}</span>
                </button>
                <Arrow
                  color={getTextColorByBgColor(data.backgroundColor)}
                  direction={openItem.id === item.id ? "down" : "right"}
                  className={cn(
                    "ml-auto transition-transform group-hover:translate-x-1",
                    openItem.id === item.id ? "!translate-x-2 rotate-90" : ""
                  )}
                />
              </div>
            ))}
          </div>

          <div className="col-span-8 col-start-6">
            {openItem.description && (
              <>
                <h3
                  className="header-3 mb-8"
                  style={{
                    color: getTextColorByBgColor(data.backgroundColor),
                  }}
                >
                  {openItem.title}
                </h3>
                <RichText
                  bgColor={data.backgroundColor}
                  data={openItem.description}
                  className="rich-text"
                />
              </>
            )}
          </div>
        </div>

        {/* Mobile */}
        <div className="md:hidden">
          <h2
            className="header-2 mb-12 text-jsc-sky-blue"
          >{data.title}</h2>

          {uniqueTags.length > 0 && (
            <div className="flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
              <div className="h-px w-full flex-1 bg-jsc-dark-gray opacity-40" />
              <div className="flex gap-2">
                {uniqueTags.map((tag) => (
                  <Tag key={tag} variant={tag} rounded />
                ))}
              </div>
            </div>
          )}

          <Accordion type="single" collapsible className="w-full">
            {data.items.map((item: any) => (
              <AccordionItem
                key={item.id}
                value={item.id}
                style={{
                  borderColor: getTextColorByBgColor(data.backgroundColor),
                }}
              >
                <AccordionTrigger
                  onClick={() => setOpenItem(item)}
                  className="font-normal"
                  style={{
                    color: getTextColorByBgColor(data.backgroundColor),
                    borderColor: getTextColorByBgColor(data.backgroundColor),
                  }}
                >
                  {item.title}
                </AccordionTrigger>
                <AccordionContent>
                  {item.description && (
                    <RichText
                      bgColor={data.backgroundColor}
                      data={openItem.description}
                      className="rich-text"
                    />
                  )}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default SimpleAccordionSection;
