"use client";

import Link from "next/link";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import Arrow from "@/components/icons/Arrow";
import { cn, getTextColorByBgColor } from "@/lib/utils";
import ChevronFill from "@/components/icons/ChevronFill";

interface RichTextProps {
  data: any;
  className?: string;
  bgColor?: string;
}

function processParagraphs(paragraphs: any) {
  paragraphs.forEach((paragraph: any) => {
    if (isLinkOnly(paragraph)) {
      paragraph.children.forEach((child: any) => {
        if (child.type === "link" && child.children) {
          child.children.forEach((linkChild: any) => {
            if (linkChild.type === "text" && !linkChild.text.includes("-link-only")) {
              linkChild.text += "-link-only";
            }
          });
        }
      });
    }
  });
}

function isLinkOnly(paragraph: any) {
  if (paragraph.type !== "paragraph" || !paragraph.children || paragraph.children.length === 0) {
    return false;
  }

  // Track the presence of exactly one link and ensure other nodes are empty text
  let linkCount = 0;

  for (const child of paragraph.children) {
    if (child.type === "text" && child.text.trim() === "") {
      continue; // Ignore empty text nodes
    } else if (child.type === "link" && child.url) {
      linkCount += 1;
      if (linkCount > 1) return false; // More than one link found
    } else {
      return false; // Any other type means this is not a "link-only" paragraph
    }
  }

  return linkCount === 1; // Return true if exactly one link was found
}

function containsIframe(children: any[]) {
  return children.some((child: any) => {
    return typeof child?.props?.text === "string" && child.props.text.trim().startsWith("<iframe");
  });
}

export default function RichText({ data, className = "rich-text relative", bgColor }: RichTextProps) {
  processParagraphs(data);

  return (
    <section className={cn(className, getTextColorByBgColor(bgColor) === "#ffffff" && "text-white")}>
      <BlocksRenderer
        content={data}
        blocks={{
          paragraph: ({ children }: any) => {
            if (containsIframe(children)) {
              return <div className="w-full" dangerouslySetInnerHTML={{ __html: children[0]?.props?.text }} />;
            }

            return <p>{children}</p>;
          },
          image: ({ image }) => {
            return (
              <img
                className="rounded-3xl"
                src={image.url.replace("localhost", "127.0.0.1")}
                alt={image.alternativeText || ""}
              />
            );
          },
          link: ({ children, url }) => {
            const external = url.startsWith("http");
            const childText = Array.isArray(children) && children[0]?.props?.text;
            if (childText?.includes("-link-only") && Array.isArray(children)) {
              const newChildren = children.map((child) => {
                return {
                  ...child,
                  props: {
                    text: childText.replace("-link-only", ""),
                  },
                };
              });

              return external ? (
                <a href={url} target="_blank" className="cta-link" rel="noopener noreferrer">
                  {newChildren}
                  <ChevronFill color={getTextColorByBgColor(bgColor)} />
                </a>
              ) : (
                <Link className="cta-link inline-flex items-center gap-4" href={url}>
                  {newChildren}
                  <ChevronFill color={getTextColorByBgColor(bgColor)} />
                </Link>
              );
            }

            return external ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            ) : (
              <Link href={url}>{children}</Link>
            );
          },
        }}
      />
    </section>
  );
}
