"use client";

import Instagram from "@/components/icons/Instagram";
import Youtube from "@/components/icons/Youtube";
import { useStore } from "@/store";
import X from "@/components/icons/X";

import { motion } from "framer-motion";
import { Facebook, Linkedin } from "lucide-react";
import Link from "next/link";
import React from "react";

const HeroSocials = () => {
  const settings = useStore((state) => state.settings);

  const renderSocialIcon = (icon: string) => {
    switch (icon) {
      case "Instagram":
        return <Instagram color={"black"} className={`h-4 w-4`} />;
      case "Youtube":
        return <Youtube color={"black"} className={`h-4 w-4`} />;
      case "Twitter":
        return <X color={"black"} className={`h-4 w-4`} />;
      case "Facebook":
        return <Facebook color={"black"} className={`h-4 w-4 fill-black stroke-none`} />;
      case "LinkedIn":
        return <Linkedin color={"black"} className={`h-4 w-4 fill-black stroke-none`} />;
      default:
        return null;
    }
  };

  return (
    <div className="absolute -bottom-[20px] right-2 z-10 flex gap-2 md:right-0">
      {settings?.Socials &&
        settings.Socials.map((social: any, i: number) => {
          return (
            <Link key={social.id} href={social.url} target="_blank" rel="noopener noreferrer">
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, ease: [0.16, 1, 0.3, 1], delay: 0.1 * i }}
                className="flex h-[40px] w-[40px] items-center justify-center rounded-full bg-white shadow-md"
              >
                <span className="sr-only">{social.title}</span>
                {renderSocialIcon(social.socialNetwork)}
              </motion.div>
            </Link>
          );
        })}
    </div>
  );
};

export default HeroSocials;
